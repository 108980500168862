import React from "react";


export default function Test() {
    return (
        <>

            <h1 style={{ color: "red" }}>Purbesh Swain</h1>

        </>
    )
}