import './App.css';
import Test from './Screens/test';

function App() {
  return (
    <Test />
  );
}

export default App;
